.notification-thumbnail-container {
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  min-width: 28px;

  .svg-thumbnail {
    height: 28px;
    width: 28px;
  }

  img {
    max-height: 28px;
  }
}

a.message {
  white-space: normal;
  text-align: left;
  line-height: $line-height-sm;
  display: inline-flex;;
  padding: 0;
  margin: 0;
  align-items: center;
}
