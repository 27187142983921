.status-tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  height: 24px;
  font-size: 14px;
  border: 0 none;
  font-weight: $font-weight-bold;
  background: #ECEAFB;
  color: $body;
  text-transform: uppercase;

  &.small {
    height: 20px;
    font-size: 12px;
  }

  &-inoperational,
  &-out-of-service {
    background: lighten($danger, 42);
    color: $danger;
  }

  &.notif {
    &.new {
      background: #ECEAFB;
      color: $body;
    }

    &.reworked {
      background: #FFF1E6;
      color: #FF6F00;
    }

    &.validated {
      background: #E7FEE9;
      color: #03AC15;
    }

    &.out-of-service {
      background: lighten($danger, 42);
      color: $danger;
    }
  }
}
