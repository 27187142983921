
.details-main-container {
  .ant-spin-container {
    height: 100%;
  }

  > .ant-card-body {
    display: flex;
    flex-grow: 1;
  }

  label {
    color: $body-secondary-color;
    font-weight: $font-weight-bold;
    font-size: 12px;
    align-items: center;
  }

  .left-pane {
    flex: 1;
    transition: flex 0.3s ease-in-out;
  }

  .right-pane {
    width: 416px;
    border-left: 1px solid $gray-200;
    padding-left: 20px;
    padding-right: 8px;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 1;
    transform: translateX(0);

    &.hidden {
      transform: translateX(100%);
      padding: 0;
      width: 0;
      opacity: 0;
      overflow: hidden;
    }

    .ant-collapse {
      &-item {
        margin-top: 8px;

        border: 2px solid $gray-100;

        &-active {
          border-color: $gray-100;
          border-radius: 6px !important;

          .ant-collapse-header {
            background-color: $white;
            //position: sticky;
            //top: 0;
            //z-index: 1001;
          }
        }

        .ant-collapse-content {
          margin-left: 24px;
        }
      }

      &-header {
        user-select: none;
        padding: 8px 12px !important;
        background-color: $gray-100;
        border-radius: 6px !important;

        > div:first-child {
          display: none !important;
        }
      }
    }

    .ant-tabs {
      position: sticky;
      top: 0;
    }

    .map-container {
      height: 296px;
    }
  }

  .sticky-top {
    z-index: 5 !important;
  }
}

nz-descriptions {
  &.main-details {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: $white;

    .ant-descriptions-row {
      &:not(:first-child) {
        .ant-descriptions-item-container {
          flex-direction: column;

          .ant-descriptions-item-label {
            padding-bottom: 2px;
          }
        }
      }

      &:first-child {
        .ant-descriptions-item-container {
          .ant-descriptions-item-label {
            font-weight: $font-weight-semibold;
          }
        }
      }
    }
  }

  &.vertical-descriptions {
    .ant-descriptions-row {
      .ant-descriptions-item-container {
        flex-direction: column;

        .ant-descriptions-item-label {
          padding-bottom: 2px;
        }
      }
    }
  }
}

.right-pane-toggle {
  position: absolute;
  right: -37px;
  top: 8px;
}

@include media-breakpoint-up(lg) {
  .asset-photo-container {
    &.middle {
      width: 85%;
    }
  }
}

@include media-breakpoint-up(xl) {
  .asset-photo-container {
    &.middle {
      width: 77.5%;
    }
  }
}
