/* Remove the popup arrow */
.leaflet-popup-tip {
  display: none;
}

/* Customize the popup padding */
.no-padding {
  .leaflet-popup-content-wrapper {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }

  .leaflet-popup-content {
    padding: 0 !important;
    margin: 0 !important;
  }
}

//
///* Style the container */
//.leaflet-control-zoom {
//  background-color: #ffffff !important;
//  border-radius: 8px !important;
//  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
//}
//
///* Style zoom-in button */
//.leaflet-control-zoom-in,
//.leaflet-control-zoom-out {
//  background-color: #007bff !important;
//  color: #ffffff !important;
//  font-size: 18px !important;
//  padding: 10px !important;
//  border: none !important;
//  display: flex !important;          /* Flexbox for centering */
//  align-items: center !important;    /* Center vertically */
//  justify-content: center !important; /* Center horizontally */
//}
//
///* Style zoom-in button */
//.leaflet-control-zoom-in {
//  border-radius: 8px 8px 0 0 !important;
//}
//
///* Style zoom-out button */
//.leaflet-control-zoom-out {
//  border-radius: 0 0 8px 8px !important;
//}
//
///* Hover effect for buttons */
//.leaflet-control-zoom-in:hover,
//.leaflet-control-zoom-out:hover {
//  background-color: #0056b3 !important;
//  cursor: pointer !important;
//}
