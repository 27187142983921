.ant {
  &-table {

    ::-webkit-scrollbar {
      width: revert;
      height: revert;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      border: 5px solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
      background-clip: padding-box;
      border-radius: 15px;
      background-color: lighten($scroll-bar, 15%);
    }

    &-pagination.ant-pagination {
      margin: 12px !important;
    }

    &-wrapper {
      &.top-pagination {
        .ant-table-pagination {
          position: absolute;
          right: 0;
          z-index: 1;
          top: 0;
        }


        .ant-table-thead {
          th::before {
            content: unset !important;
          }
        }

        &.small {
          .ant-table-pagination {
            top: -4px;
          }
        }
      }

      &.custom-table-header nz-table-title-footer{
        background-color: #fafafa !important;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
