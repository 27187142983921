/// SCROLLER
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: $gray-100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: lighten($scroll-bar, 15%);
  border-radius: 15px;
  opacity: 0.5;

  &:hover {
    background-color: $scroll-bar;
  }
}

.icon-btn {
  cursor: pointer;
}

// ng-zorro override
.ant {

  &-modal-content {
    .ant-btn:not(.ant-btn-circle) {
      min-width: 80px;
    }
  }

  &-card {
    &-head {
      border-bottom: 0 none;
    }
  }

  &-select {
    &.bg-danger-subtle {
      background-color: transparent !important;

      .ant-select-selector {
        background-color: #FEE7E7;
        border: 1px solid #FEE7E7;
      }
    }
  }

  &-descriptions {
    &-view {
      overflow: hidden;

      .ant-descriptions {
        &-item-label {
          color: $body-secondary-color;
          font-weight: $font-weight-bold;
          font-size: 12px;
          align-items: center;
        }

        &-item-content {
          font-size: 14px;
          color:$gray-800;
        }
      }
    }

    &-header {
      margin-bottom: 16px;
    }
  }

  &-form-item-control {
    &.has-textarea-count {
      .ant-form-item-explain {
        min-height: 0 !important;
      }

      app-field-validator {
        bottom: 0;
        position: absolute;
      }
    }
  }

  &-input-prefix {
    margin-right: 8px;
  }

  &-popover {
    &-placement-bottomRight {
      left: 14px;
    }
    &-inner-content {
      .popover-form {
        width: 256px;
      }

    }
  }

  &-badge-count-sm {
    font-size: 8px;
    line-height: 14px;
    padding: 0;
  }

  &-tabs {
    &-tab + .ant-tabs-tab {
      margin-left: 8px !important;
    }
  }
}

// layout
.right-layout {
  nz-content {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .main-container {
      flex-direction: column;
    }

    .split-panel-container {
      padding: 8px 24px 16px;
    }

    .main-container,
    .split-panel-container {
      display: flex;
      flex-grow: 1; /* Take up the remaining space */
      overflow: hidden; /* Prevent the content div itself from scrolling */
    }
  }
}

nz-collapse-panel.p-collapse-0.ant-collapse-item .ant-collapse-header {
  padding: 12px 0px !important;
}

nz-input-group.ant-input-affix-wrapper .ant-input {
  border-radius: 0 !important;
}

nz-transfer-list {
  width: 100% !important;
}

.ant-table-expanded-row-fixed {
  width: 100% !important;
}

.ant-avatar-string {
  position: initial !important;
}

svg-icon {
  svg {
    &.primary {
      path {
        fill: $blue;
      }
    }
  }
}

a {
  text-decoration: none !important;
}

.required {
  &::before {
    display: inline-block;
    margin-right: 4px;
    color: $danger;
    font-size: 12px;
    line-height: 1;
    content: "*";
  }
}

.preserve-whitespace {
  white-space: break-spaces;
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.ant-btn:disabled {
  svg-icon {
    .action {
      path {
        stroke: $gray-600 !important;
      }
    }
  }
}

svg-icon {
  .action {
    path {
      stroke: $primary;
    }

    &:hover {
      path {
        stroke: darken($primary, 5%);
      }
    }

    &-filled {
      &:hover {
        path {
          fill: $primary;
        }
      }
    }
  }
}

.ant-btn-dangerous:not(:disabled) {
  svg-icon {
    .action {
      path {
        stroke: $danger;
      }

      &:hover {
        path {
          stroke: darken($danger, 5%);
        }
      }
    }
  }
}

.ant-transfer-operation {
  button {
    min-width:30px !important;
    max-width: 80px !important;
  }
}

.notification-container {
  .ant-list-loading {
    .ant-spin-nested-loading {
      min-height: 148px;
    }
  }
}

.ant-checkbox-wrapper {
  white-space: normal !important;
  max-width: fit-content !important;
}

.rg-20 {
  row-gap: 20px;
}

.other-filter-container {
  align-self: center;
  margin-bottom: 8px;
}

.component-popover .ant-popover-inner-content {
  padding: 0 !important;
}

.ant-upload-list-item-card-actions-btn {
  display: flex;
  justify-content: center;
}