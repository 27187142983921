.issue-timeline-container {
  .ant-timeline-item {
    font-size: $font-size-sm;
    min-width: 288px !important;
    padding-bottom: 8px;

    &:last-child {
      padding-bottom: 0;
    }

    &-content {
      min-height: 24px !important;
      line-height: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      left: calc(40% - 4px) !important;
      width: calc(60% - 14px) !important;
    }

    &-label {
      width: calc(40% - 12px) !important;
    }

    &-head {
      top: -2px;
      left: 40% !important;
    }

    &-tail {
      top: 8px;
      left: 40% !important;
    }
  }
}
