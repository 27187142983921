// left panel for virtual list
nz-card {
  &.virtual-list-container {
    box-shadow: 2px 4px 16px rgba(24, 15, 73, 0.08);
    width: 280px;
    overflow: hidden;
    margin-right: 24px;

    .ant-card-body {
      height: 100%;
      padding: 0;
    }

    cdk-virtual-scroll-viewport {
      height: inherit;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &.list-loading {
    cdk-virtual-scroll-viewport {
      overflow-y: hidden;
    }
  }
}

nz-list {
  nz-list-item {
    height: 80px;
    padding: 0 16px !important;

    &.selected-item {
      background: #ECEAFB;
    }

    &:not(.display-only) {
      cursor: pointer;

      &:hover:not(.selected-item) {
        background: #F7F7F8;
      }
    }

    .thumbnail-container {
      height: 40px;
      width: 40px;
      min-width: 40px;
      margin-right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      .svg-thumbnail {
        height: 40px;
        width: 40px;
      }

      img {
        max-height: 40px;
      }

      &.small {
        height: 32px;
        width: 32px;
        min-width: 32px;

        .svg-thumbnail {
          height: 32px;
          width: 32px;
        }

        img {
          max-height: 32px;
        }
      }
    }

    .item-detail-container {
      display: flex;
      flex-direction: column;
      grid-row-gap: 4px;
      max-width: 176px;

      .item-primary {
        color: $gray-900;
        font-size: $font-size-base;
        font-weight: $font-weight-semibold;
        min-width: 0;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .item-secondary {
        color: $body-secondary-color;
        font-weight: $font-weight-light;
      }
    }
  }
}

// hide right panel for medium and smaller screen size
@include media-breakpoint-down(lg) {
  nz-card {
    &.virtual-list-container {
      width: 100% !important;
      margin-right: 0;
    }
  }
  .item-full-detail-container {
    display: none !important;
  }
}

// right panel
.item-full-detail-container {
  width: calc(100% - 296px);
  overflow: hidden;
  overflow-y: auto;
  padding-right: 8px;

  nz-card {
    min-height: 100%;
    border: 0 none;

    .ant-card-body {
      padding: 0 !important;
    }
  }
}

// placeholder when no item is selected from the list
.select-item-tip-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  h4 {
    margin-top: -100px;
    text-align: center;
    max-width: 520px;
    padding: 0 2rem;
  }
}

.skeleton-loader-container {
  .ant-skeleton-title {
    width: 98% !important;
  }
}

.asset-id-with-icon {
  background-image: url("assets/icons/vehicle.svg");
  background-repeat: no-repeat;
  background-position: 6px center;
  padding: 1px 1px 1px 24px;
  background-color: $body;
  border-radius: 6px;
  display: flex;
  height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.asset-id-link {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      .asset-id-text {
        background-color: $gray-200;
      }
    }
  }

  .asset-id-text {
    background-color: $white;
    border-radius: 0 5px 5px 0;
    padding: 0 4px 0 3px;
    font-weight: $font-weight-bold;
    font-size: 13px;
    color: $primary;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.icon-status {
  background-repeat: no-repeat;
  background-position: center;
  height: 21px;
  width: 21px;
  flex: 0 0 auto;

  &.critical {
    background-image: url("assets/icons/critical_filled.svg");
  }
  &.minor,
  &.non-critical {
    background-image: url("../assets/icons/minor_filled.svg");
  }
  &.in-repair {
    background-image: url("assets/icons/in_repair_filled.svg");
  }
  &.new {
    background-image: url("assets/icons/new.svg");
  }
  &.fixed {
    background-image: url("assets/icons/fixed_filled.svg");
  }
  &.no-fault {
    background-image: url("assets/icons/no_fault_filled.svg");
  }
  &.validating {
    background-image: url("assets/icons/validating_filled.svg");
  }
  &.operational {
    background-image: url("assets/icons/operational.svg");
  }
  &.inoperational,
  &.out-of-service {
    background-image: url("assets/icons/inoperational.svg");
  }
}
