.card-shadow-container {
  row-gap: 20px;

  .card-shadow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;

    display: flex;
    flex-direction: column;
    padding: 8px 24px;

    background: $white;
    box-shadow: 2px 4px 16px rgba(24, 15, 73, 0.08);
    border-radius: 12px;

    .ant-card-head,
    .ant-card-body {
      padding: 0 !important;
      width: 100%;
    }
  }
}