@import "variables";

.dashboard-container {
  .widget {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    padding: 8px 24px;
    gap: 8px;

    margin: 0 auto;
    height: 120px;

    background: $white;
    box-shadow: 2px 4px 16px rgba(24, 15, 73, 0.08);
    border-radius: 12px;

    .ant-card-head,
    .ant-card-body {
      padding: 0 !important;
      width: 100%;
    }

    /* Threshold line and arrow */
    .threshold-line {
      position: absolute;
      height: 14px;
      display: flex;
      justify-content: center;
      pointer-events: none;
      transform: translateX(-50%);
      top: -4px;

      .threshold-label {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        color: $primary;
        padding: 2px 4px;
        font-size: 12px;
        border-radius: 4px;
        white-space: nowrap;
      }

      .threshold-arrow {
        //width: 0;
        //height: 0;
        //border-left: 6px solid transparent;
        //border-right: 6px solid transparent;
        //border-bottom: 10px solid $primary;
        width: 2px;
        height: 14px;
        border-left: 2px solid #888;
        border-radius: 4px;
      }
    }

    /* Main container */
    .response-time-bar-container,
    .fleet-health-bar-container {
      position: relative;
      width: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      text-align: center;

      /* Bar container */
      .response-time-bar,
      .fleet-health-bar {
        position: relative;
        width: 100%;
        height: 6px;
        background: #D7D7DB;
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 4px;
      }
    }

    /* Dynamic bar (colored part) */
    .bar {
      height: 100%;
      transition: width 0.3s, background-color 0.3s;
    }

    .status-label {
      text-align: left;
      white-space: nowrap;
      font-size: $h2-font-size;
      font-weight: $font-weight-bold;
    }
  }

  .loading-bar-container {
    position: relative;
    width: 100%;
    height: 46px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .loading-text {
      display: flex;
      align-items: center;
      color: $gray-600;
      font-size: 14px;
      font-weight: 500;
      .dot {
        width: 8px;
        height: 8px;
        margin-left: 4px;
        background-color: lighten($primary, 15%);
        border-radius: 50%;
        animation: loading-dots 1.5s infinite ease-in-out;
        &:nth-child(2) {
          animation-delay: 0.2s;
        }
        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  /* Loading text with dots */
  @keyframes loading-dots {
    0%,80%,100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }

  .bg-danger {
    background-color: #D82020;
  }

  .text-danger {
    color: #D82020;
  }

}
