.filter-tags-container {
  display: flex;
  min-height: 32px;
  min-width: 48px;
  flex-wrap: wrap;
  gap: 4px;

  .filter-title {
    align-self: center;
    margin-bottom: 8px;
    margin-right: 15px;
  }

  .ant-tag-checkable {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    margin-bottom: 8px !important;
    border-radius: 6px;
    background-color: #F7F7F8;
    border-color: transparent;
    color: #525159;
    cursor: pointer;
    font-weight: $font-weight-light;
    font-size: 14px;
    height: 32px;
    user-select: none;

    &-checked {
      background-color: #ECEAFB;
      border: 1px solid $primary;
      color: $primary;
    }

    &.critical,
    &.fixed,
    &.in-repair,
    &.inoperational,
    &.out-of-service,
    &.operational,
    &.new,
    &.no-fault,
    &.minor,
    &.non-critical,
    &.validating,
    &.validated,
    &.reworked {
      background-repeat: no-repeat;
      background-position: 8px center;
      padding-left: 36px !important;
      background-size: 20px;
    }

    &.critical {
      background-image: url("assets/icons/critical.svg");
    }
    &.minor,
    &.non-critical, {
      background-image: url("../assets/icons/minor.svg");
    }
    &.in-repair,
    &.reworked {
      background-image: url("assets/icons/in_repair.svg");
    }
    &.fixed,
    &.validated {
      background-image: url("assets/icons/fixed.svg");
    }
    &.no-fault {
      background-image: url("assets/icons/no_fault.svg");
    }
    &.validating {
      background-image: url("assets/icons/validating.svg");
    }
    &.new {
      background-image: url("assets/icons/new.svg");
    }
    &.inoperational,
    &.out-of-service {
      background-image: url("assets/icons/inoperational.svg");
    }
    &.operational {
      background-image: url("assets/icons/operational.svg");
    }
  }

}